<template>
  <div class="mobile-container">
    <userHeader custom-active="3"></userHeader>
    <div class="tools">
      <el-tabs class="custom-tabs" v-model="activeName" @tab-click="changeTab">
        <el-tab-pane :label="$t('lang.myNFT')" name="0"></el-tab-pane>
        <el-tab-pane :label="$t('lang.participation')" name="1"></el-tab-pane>
        <el-tab-pane :label="$t('lang.follow')" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <ul class="tabs-list">
      <li>
        <ul class="list">
          <!-- <div class="tools" v-if="activeName == '1'">
            <div @click="handleFilter">
              <span>{{ FilterList[Filter].name }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div> -->
          <!-- my NFT -->
          <div v-if="activeName == '0'">
            <li v-for="(item, i) of dataSource" :key="i" class="list1">
              <div class="img-box">
                <img :src="item.url" class="view" alt="" />
                <img  src="./../../assets/img/creat.png"  class="bg-img" @click="toDetail(item)"  alt=""  />
                <!-- <div  class="info-text" v-if="item.is_running || (item.status == 1 && item.isEnd)" >
                  {{ $t("lang.waiting") }}
                </div> -->

                <!-- 出售价格 -->
                <div class="card-info" v-if="item.is_on_selling == 2 && item.is_running == 1 && item.is_check == 5 ">
                  <p>
                    <img :src="require('@/assets/img/shopcart.svg')" alt="" />
                    <span>{{ $t("lang.sellPrice") }}</span>
                  </p>
                  <p>
                    <img src="./../../assets/img/icon.png" alt="" />
                    <span class="price">{{ item.price }}</span>
                  </p>
                </div> 

                <!-- 待审核 审核失败 文字提示 -->
                <div class="info-examin"  >
                  <span v-if="item.is_check == 1">{{ $t("lang.UnderReview") }}</span>
                  <span v-if="item.is_check == 3" class="Failed">{{ $t("lang.Failed") }}</span>
                </div>

                 <!-- 参与游戏 -->
                <div class="foot" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
                  <img src="../../assets/img/icon.sm.png" />
                  <div class="progress-n">
                    <div class="progress-num">
                      <span>{{ item.approve_count }}</span>
                      <span class="cor">{{ item.defuse_count }}</span>
                    </div>
                    <div class="progress">
                      <span  class="progressing" v-bind:style="'width:' + item.Rate * 100 + '%'"   ></span>
                    </div>
                  </div>
                  <img src="../../assets/img/icon-k.png" />
                </div>
                <div class="mark"># {{ item.id }}</div>
              </div>
              <div class="info">
                <div class="row">
                  <h5>  {{   item.owner_name }} </h5>
                  <h4>{{ item.title }}</h4>
                </div>
                <div class="row">
                  <h5>  {{ $t("lang.value") }} </h5>
                  <h4>
                    <img src="./../../assets/img/icon.png" width="20px" height="20px"  alt="" />
                    <span >{{  item.amount || 0  }}</span>
                  </h4>
                </div>
                <!-- <div class="row">
                  <div :class="{ row: item.is_on_selling != 1 }">
                    <span>{{ $t("lang.value") }}</span>
                    <p>
                      <img src="./../../assets/img/icon.png" alt="" />
                      <span v-if="item.is_on_selling == 1">{{  item.price == "" ? "~" : item.price  }}</span>
                      <span v-else>{{  item.amount == "" ? "~" : item.amount  }}</span>
                      <span >{{  item.amount == "" ? "~" : item.amount  }}</span>
                    </p>
                  </div>
                  <img  :src="require('@/assets/img/circle-arrow.svg')"  class="icon"   @click="cancelSell(item.TradeId)"  v-if="item.is_on_selling == 1"  />
                </div> -->

                <!-- 审核 -->
                <div  class="mitn_btn"  v-if=" item.is_check !=5  "  >
                  <span  v-if="item.is_check == 2 && item.isSame ==0"  @click="shouMintDialog(item)">{{$t("lang.Mint")}}</span  >
                  <span class="del-button" v-if="item.is_check == 3&& item.isSame ==0"  @click="handlleUnbind(item)"   >{{$t("lang.Delete")}}</span>
                  <!-- <span class="mint-button" v-else   >{{$t("lang.Mint")}}</span> -->
                  <!-- <img v-if="item.is_check == 3" src="@/assets/img/ellipsis.png"  @click="handlleUnbind(item)"   class="ellipsis"  alt="" /> -->
                  <!-- <span class="unbind">unbind</span> -->
                </div>
                
                <!-- 开始游戏 -->
                <!-- <div class="btn-info"  :class="{ 'ja-btn-info': langs == 'ja' || langs == 'ko' }"   v-if=" item.is_on_selling == 1 &&   item.is_running ==1 &&  item.is_check ==5 " >
                  <div style="background: #f2f2f2;">  {{ $t("lang.publish") }} </div>
                  <div  class="Sell" v-if="item.next_sale_time  - new Date().getTime() < 0"   @click="handleSell(item)"  >
                    {{ $t("lang.sell") }}
                  </div>
                  <div class="Sell" v-else> 
                    <countDate  size="small"  :remain-time="   item.next_sale_time - new Date().getTime()  "  @sell="handleSell(item)"/>
                  </div>
               
                </div> -->
                <div class="btn-info"  :class="{ 'ja-btn-info': langs == 'ja' || langs == 'ko' }"   v-if=" item.is_on_selling == 1 &&   item.is_running ==1 &&  item.is_check ==5&& item.isSame ==0 " >
                  <div @click="isOnStartGame(item.id)">  {{ $t("lang.publish") }} </div>
                  <div  class="Sell" v-if="item.next_sale_time  - new Date().getTime() < 0"   @click="handleSell(item)"  >
                    {{ $t("lang.sell") }}
                  </div>
                  <div class="Sell" v-else> 
                    <countDate  size="small"  :remain-time="   item.next_sale_time - new Date().getTime()  "  @sell="handleSell(item)"/>
                  </div>
                  <!-- <img  src="@/assets/img/ellipsis.png"  @click="handlleUnbind(item)"   class="ellipsis"  alt="" /> -->
                </div>
              </div>
            </li>
            <li class="nodata" v-if="dataSource.length == 0">
              <img src="./../../assets/img/nodata_items.png" alt="" />
            </li>
          </div>
          <div v-if="activeName == '1'">
            <li v-for="(item, i) of dataSource" :key="i" class="list2">
              <div class="img-box">
                <img :src="item.url" class="view" alt="" />
                <img src="./../../assets/img/creat.png" class="bg-img" @click="toHistoryDetail(item)"  alt="" />
                <!-- <div class="foot" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
                  <img src="../../assets/img/icon.sm.png" />
                  <div class="progress-n">
                    <div class="progress-num">
                      <span>{{ item.approve_count }}</span>
                      <div class="win" v-if="item.result == 1"></div>
                      <div class="lose" v-if="item.result == 2"></div>
                      <div class="draw" v-if="item.result == 3"></div>
                      <span class="cor">{{ item.defuse_count }}</span>
                    </div>
                    <div class="progress">
                      <span  class="progressing"  v-bind:style="'width:' + item.Rate * 100 + '%'"></span>
                    </div>
                  </div>
                  <img src="../../assets/img/icon-k.png" />
                </div> -->
                <div class="mark"># {{ item.id}} - {{ item.game_id }}</div>
              </div>
              <div class="info">
                <div class="row">
                  <h5>  {{   item.owner_name }} </h5>
                  <h4>{{ item.title }}</h4>
                </div>
                <div class="row rowItem">
                  <span>{{ $t("lang.value") }}</span>
                  <p>
                    <img src="./../../assets/img/icon.png" alt="" />
                    <span >{{  item.amount == "" ? 0 : item.amount  }}</span>
                  </p>
                </div>
              </div>

              <!-- <div class="btn-info"  :class="{ 'ja-btn-info': langs == 'ja' || langs == 'ko' }"   v-if=" item.is_on_selling == 1 &&   item.is_running ==1 &&  item.is_check ==5 " >
                  <div @click="OnStartGame(item.id)">  {{ $t("lang.publish") }} </div>
                  <div  class="Sell" v-if="item.next_sale_time  - new Date().getTime() < 0"   @click="handleSell(item)"  >
                    {{ $t("lang.sell") }}
                  </div>
                  <div class="Sell" v-else> 
                    <countDate  size="small"  :remain-time="   item.next_sale_time - new Date().getTime()  "  @sell="handleSell(item)"/>
                  </div>
                  <img  src="@/assets/img/ellipsis.png"  @click="handlleUnbind(item)"   class="ellipsis"  alt="" />
              </div> -->

            </li>
            <li class="nodata" v-if="dataSource.length == 0">
              <img src="./../../assets/img/nodata_participated.png" alt="" />
            </li>
          </div>
          <!-- collect -->
          <div v-if="activeName == '2'">
            <li v-for="(item, i) of dataSource" :key="i" class="list3">
              <div class="img-box">
                <!-- <div class="collect" @click="addCollect(item)">
                  <img
                    :src="require('@/assets/img/heart-fill.svg')"
                    v-if="item.IsCollect"
                    alt=""
                  />
                  <img :src="require('@/assets/img/heart.svg')" v-else alt="" />
                </div> -->
                <img :src="item.url" class="view" alt="" />
                <img  src="./../../assets/img/creat.png" class="bg-img"    alt=""  />
                <!-- <div class="info-text" v-if="item.is_running">
                  {{ $t("lang.waiting") }}
                </div> -->
                <div class="card-info" v-if="item.is_on_selling == 2 && item.is_running == 1 && item.is_check == 5 ">
                  <p>
                    <img :src="require('@/assets/img/shopcart.svg')" alt="" />
                    <span>{{ $t("lang.sellPrice") }}</span>
                  </p>
                  <p>
                    <img src="./../../assets/img/icon.png" alt="" />
                    <span class="price">{{ item.price }}</span>
                  </p> 
                </div>
                
               
                <!-- <div class="btn-info"  v-if="   item.is_on_selling == 0 && item.isOwner &&   !item.is_running &&  !item.TokenID   "  >
                  <div @click="OnStartGame(item.id)">
                    {{ $t("lang.publish") }}
                  </div>
                  <div class="Sell" @click="handleSell(item)">
                    {{ $t("lang.sell") }}
                  </div>
                </div> -->

                <div class="foot" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
                  <img src="../../assets/img/icon.sm.png" />
                  <div class="progress-n">
                    <div class="progress-num">
                      <span>{{ item.approve_count }}</span>
                      <div class="win" v-if="item.result == 1"></div>
                      <div class="lose" v-if="item.result == 2"></div>
                      <div class="draw" v-if="item.result == 3"></div>
                      <span class="cor">{{ item.defuse_count }}</span>
                    </div>
                    <div class="progress">
                      <span
                        class="progressing"
                        v-bind:style="'width:' + item.Rate * 100 + '%'"
                      ></span>
                    </div>
                  </div>
                  <img src="../../assets/img/icon-k.png" />
                </div>
                <div class="mark"># {{ item.id }}</div>
              </div>
              <div class="info">
                <div class="row">
                  <h5>  {{   item.owner_name }} </h5>
                  <h4>{{ item.title }}</h4>
                </div>
                <div class="row rowItem">
                  <span>{{ $t("lang.value") }}</span>
                  <p>
                    <img src="./../../assets/img/icon.png" alt="" />
                    <span >{{  item.amount == "" ? 0 : item.amount  }}</span>
                  </p>
                </div>
              
              </div>
              <!-- <div class="btn-info"  :class="{ 'ja-btn-info': langs == 'ja' || langs == 'ko' }"   v-if=" item.is_on_selling == 1 &&   item.is_running ==1 &&  item.is_check ==5 " >
                  <div @click="OnStartGame(item.id)">  {{ $t("lang.publish") }} </div>
                  <div  class="Sell" v-if="item.next_sale_time  - new Date().getTime() < 0"   @click="handleSell(item)"  >
                    {{ $t("lang.sell") }}
                  </div>
                  <div class="Sell" v-else> 
                    <countDate  size="small"  :remain-time="   item.next_sale_time - new Date().getTime()  "  @sell="handleSell(item)"/>
                  </div>
                  <img  src="@/assets/img/ellipsis.png"  @click="handlleUnbind(item)"   class="ellipsis"  alt="" />
                </div> -->

            </li>
            <li class="nodata" v-if="dataSource.length == 0">
              <img src="./../../assets/img/nodata_follow.png" alt="" />
            </li>
          </div>
        </ul>
      </li>
    </ul>
    <Footer></Footer>
    <sale-drawer ref="SaleDrawer" @srcFail="srcFail"></sale-drawer>
    <!-- 铸造弹窗 -->
    <el-dialog   :visible="showMint" :title='$t("lang.COMPLETE")'  :close-on-click-modal="false"  width="85%" custom-class="mint-dialog"  @close="closeDialog"  >
      <div class="left-img" v-if="item.url">
        <img :src="item.url" />
      </div>
      <ul>
        <li><span>Title：</span>{{ item.title }}</li>
        <li>
          <span>{{ $t("lang.value") }}:</span>
          <p>
            <img src="./../../assets/img/icon.png" alt="" />
            <span>{{ price }}</span>
          </p>
        </li>
        <li><span>description:</span>{{ item.description }}</li>
      </ul>
      <div class="mint-Confirm">
        <el-button @click="handleWord()" :loading="btnLoading" round   >{{$t("lang.ConfirmCheckOut")}}</el-button >
      </div>
    </el-dialog>
    <el-dialog   :visible="showWord" :title='$t("lang.PasswordVerification")'  :close-on-click-modal="false"  width="85%" custom-class="mint-dialog"  @close="closeWord"  >
      <ul >
        <!-- <li><span>Title：</span>{{ item.title }}</li>
        <li><span>description:</span>{{ item.description }}</li> -->
        <li>
          <span>{{ $t("lang.value") }}:</span>
          <p>
            <img src="./../../assets/img/icon.png" alt="" />
            <span>{{ price }}</span>
          </p>
        </li>
      </ul>
      <el-input :placeholder="$t('lang.PleasePassword')" v-model="password" show-password></el-input>
      <div class="mint-Confirm">
        <el-button @click="handleMint()" :loading="btnLoadings" round   >{{$t("lang.submit")}}</el-button >
      </div>
    </el-dialog>
    <el-dialog :visible="showRemove" width="70%"  :show-close="false" :title="null" :center="true">
      <div class="confirm">
        <h2>
          {{ $t("lang.AreYouSure") }} <br />
          {{ $t("lang.ToRemove") }}
        </h2>
        <div class="button">
          <span @click="handleRemove(true)">{{ $t("lang.confirmText") }}</span>
          <span @click="handleRemove(false)">{{ $t("lang.cancelText") }}</span>
        </div>
      </div>
    </el-dialog>
    <Cancel  :ShowTooltip="ShowTooltip"  :TradeId="TradeId"  @closeTooltip="closeTooltip"></Cancel>
    <connectMetamask ref="showMetamask"></connectMetamask>
    <!-- 失败弹窗 -->
    <Fail ref="failed"></Fail>
    <Bind ref="bind"></Bind>
    <select-popover @close="handleClose" @change="handleChange" :show="visible" :List="FilterList"
      :active="Filter" :size="isFilter ? '200px' : '280px'" :name="true"></select-popover>
  </div>
</template>
<script>
import Header from "@/components/mobile/header";
import userHeader from "@/components/mobile/userHeader";
import Footer from "@/components/mobile/footer";
import SaleDrawer from "./modules/saleDrawer";
import Fail from "./modules/Failed.vue";
import request from "@/services/request";
import ad from "../../utils/web3Helper.js";
import market from "@/utils/market";
import {BASE_V_API,countDownTime} from "@/config.js"
import CountDate from "@/components/CountDate.vue";
import Bind from "./modules/bind/index";
import Cancel from "@/components/mobile/cancel.vue";
import selectPopover from "@/components/mobile/select-popover.vue";
import EventBus from '@/utils/EventBus'
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet,axiosPost} from '@/api/api';

export default {
  name: "mine-m",
  data() {
    return {
      url:{
        info:BASE_GLOB_API+'/user/info',
       },
      Filter: 0, //1: 按价格排序 2：总参与人数 3：阵营差值 4：我收藏的(key 需要传入当前地址) 5；即将结束的
      FilterList: [
        { name: this.$t("lang.AllOption"), value: 1 },
        { name: this.$t("lang.WaitingSettlement"), value: 2 },
        { name: this.$t("lang.Finished"), value: 3 },
      ],
      activeName: "0",
      dataSource: [],
      base: "",
      sort: 1,
      loading: null,
      tokenID: "",
      ShowTooltip: false,
      showMint: false,
      price: "0.1",
      item: "",
      btnLoading: false,
      btnLoadings:false,
      TradeId: null,
      timers: "",
      showWord:false,
      password:'',
      isClick:true,
      visible: false,
      isFilter: true,
      showRemove:false,
      removeId:''
    };
  },
  components: {
    SaleDrawer,
    Header,
    Footer,
    Fail,
    CountDate,
    Bind,
    Cancel,
    selectPopover,
    userHeader
  },
  computed: {
    address() {
      return this.$store.getters.address;
    },
    langs() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.useInfo()
    this.getMyNFT();
    this.base = BASE_V_API;
    EventBus.$on('share', value => {
      // 将 A 组件传递过来的数据保存到 B 组件
           if(value){
            this. FilterList=[
               { name: this.$t("lang.AllOption"), value: 1 },
               { name: this.$t("lang.WaitingSettlement"), value: 2 },
               { name: this.$t("lang.Finished"), value: 3 },
            ];
            this.changeTab();
           }

        })
  },
  methods: {
    useInfo(){
          axiosGet(this.url.info).then(res=>{
              if(res.success){ 
                  window.localStorage.setItem("userinfo", JSON.stringify(res.result));
              }else{
                  this.$message.error(res.message);
              }
              
          })
        },
    handleChange(i) {
      if (this.isFilter) {
        this.Filter = i;
      }
      this.visible = false;
      this.getMyHistory();
    },
    handleClose() {
      this.visible = false;
    },
    handleFilter() {
      this.visible = true;
      this.isFilter = true;
    },
    closeWord(){
      this.showWord =false
      this.password =""
    },
    srcFail(){
       this.getMyNFT();
    },
    //关闭弹窗
    closeTooltip() {
      this.ShowTooltip = false;
    },
    handlleUnbind(item) {
      this.showRemove =true;
      this.removeId = item.id;
      // this.$refs.bind.showBind(item);
    },
    handleRemove(flag) {
      if (flag) {
        var param = {
          nft_id: this.removeId,
        };
        request.postDelete(param).then(
          (response) => {
            if (response.code == "200") {
              this.removeId ='';
              this.showRemove = false;
              this.getMyNFT();
              this.$message.success(response.message);
            } else {
              this.$message.error(response.message);
            }
          },
          (err) => {
            console.log(err, "err");
          }
        );
      } else {
        this.showRemove = false;
      }
    },
    handleWord(){
      this.btnLoading =false
      this.showWord =true
    },
    //mint
    handleMint() {
      this.btnLoadings = true;
      // this.showLoading();
      if (this.password.length == 0) {
        this.btnLoadings = false;
        this.$message.error(this.$t("lang.PleasePassword"));
        return;
      }
      var para = {
        nft_id: this.item.id,
        payPassword:this.password
      };

      let that = this;
      request.getMint(para).then((res) => {
        if (res.code == 200) {
          that.btnLoadings = false;
          that.password =""
          that.getMyNFT();
          that.closeDialog()
          that.closeWord()
          that.$message.success(res.message);
        }else{
          that.$message.error(res.message);
          that.password =""
          that.loading.close();
          that.btnLoadings = false;
            var data = {
              title: this.$t("lang.transactionFailed"),
              desc: this.$t("lang.failedDesc"),
            };
            that.$emit("fail", data);
        }
        
      }).catch((error) => {
        // console.log("111",error.msg);
        that.password =""
        that.btnLoadings = false;
        that.$message.error(error.message);

       });
      // var para = {
      //   cid: this.item.Cid,
      //   description: this.item.description,
      //   owner: this.address,
      //   title: this.item.title,
      // };
      // request.getUrl(para).then((res) => {
      //   ad.mintToSelf(
      //     res.data.url,
      //     res.data.id,
      //     (hash) => {
      //       this.loading.close();
      //       this.closeDialog();
      //     },
      //     (err) => {
      //       this.loading.close();
      //       var data = {
      //         title: this.$t("lang.transactionFailed"),
      //         desc: this.$t("lang.failedDesc"),
      //       };
      //       that.$emit("fail", data);
      //     }
      //   );
      // });
    },
    closeDialog() {
      this.showMint = false;
    },
    shouMintDialog(item) {
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login-m'});
        // this.$router.push({name:'login-m',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        if (item.is_check != 2) return;
        this.item = item;
        this.showMint = true;
        // ad.getPrice((res) => {
        //   this.price = ad.fromWei(res);
        //   this.item = item;
        //   this.showMint = true;
        // });
      }
    },
    toDetail(item) {
      this.$router.push({
        name: "nftDetail-m",
        query: { tokenID: item.id },
      });
    },
    toHistoryDetail(item) {
      this.$router.push({
          name: "nftHistory-m",
          query: { tokenID: item.game_id },
        });
    },
    //出售
    handleSell(item) {
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login-m'});
        // this.$router.push({name:'login-m',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        // console.log(item, "handleSell");
        // this.$refs.SaleDrawer.item = item;
        this.$refs.SaleDrawer.openDialog(item);
      }
    },
    updateList() {
      if (this.activeName == "0") {
        this.getMyNFT();
      } else if (this.activeName == "1") {
        this.dataSource = [];
        this.getMyHistory();
      } else if (this.activeName == "2") {
        this.dataSource = [];
        this.getMyCollected();
      }
    },
    //关闭弹窗
    CloseTooltip() {
      this.ShowTooltip = false;
    },
    //下架弹窗
    cancelSell(TradeId) {
      let token = this.getStorageToken();
      if (this.address == "") {
        this.$refs.showMetamask.show = true;
      } else if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
      } else {
        this.TradeId = TradeId;
        this.ShowTooltip = true;
      }
    },
    //收藏 or 取消收藏
    addCollect(item) {
      let token = sessionStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
      } else {
        var para = {
          address: this.address,
          token_id: item.TokenID,
        };
        if (item.IsCollect == true) {
          request.delMyCollect(para).then((response) => {
            if (response.code == 200) {
              this.$message({
                message: "删除收藏成功",
                type: "success",
              });
              this.updateList();
            }
          });
        } else {
          request.addMyCollect(para).then((response) => {
            if (response.code == 200) {
              this.$message({
                message: "添加收藏成功",
                type: "success",
              });
              this.updateList();
            }
          });
        }
      }
    },
    //获取My NFT 列表数据
    getMyNFT() {
      let token = localStorage.getItem("Token");
      let userInfo = JSON.parse(localStorage.getItem("userinfo"));
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
      } else {
        // this.showLoading();
        var time = Date.parse(new Date()) / 1000; //获取当前时间
        var para = {
          // key: this.address,
          page: 1,
          page_size: 1000,
          sort: 1,
        };
        this.dataSource = [];
        request.searchnft(para).then(
          (response) => {
            if (response.code == "200") {
              this.dataSource = response.data;
              this.dataSource.forEach((item, index) => {
                let owner_name = this.dataSource[index].owner_name;
                // if (owner == this.address) {
                //   this.dataSource[index].isOwner = true;
                // }
                if(userInfo){
                  console.log("out_user_id",(String(item.out_user_id) == String(userInfo.id)))
                    if (String(item.out_user_id) == String(userInfo.id)) {
                      item.isSame = 0;
                    } else {
                      item.isSame = 1;
                    }
                  }else{
                    item.isSame =2
                  }
                this.dataSource[index].isEnd = false;
              if(this.dataSource[index].next_sale_time){
                var date = new Date(item.next_sale_time)
                this.dataSource[index].next_sale_time = date.getTime();    
                console.log("3333",this.dataSource[index].next_sale_time)
               }else{
                this.dataSource[index].next_sale_time = 0;
               }
                // if (
                //   this.dataSource[index].StartGame &&
                //   this.dataSource[index].StartGame != "" &&
                //   this.dataSource[index].StartGame.trim().length > 0
                // ) {
                //   var endTime =
                //     parseInt(this.dataSource[index].StartGame) + 600; //游戏结束时间
                //   if (time > endTime) {
                //     this.dataSource[index].isEnd = true;
                //   }
                // }
                // this.dataSource[index].price = ad.fromWei(
                //   this.dataSource[index].price
                // );
                // this.dataSource[index].amount = ad.fromWei(
                //   this.dataSource[index].amount
                // );
                // var ac = parseInt(item.approve_count);
                // var dc = parseInt(item.defuse_count);
                // if (ac == 0 && dc == 0) {
                //   this.dataSource[index].Rate = 0.5;
                // } else {
                //   this.dataSource[index].Rate = ac / (ac + dc);
                // }
                this.dataSource[index].owner_name = this.dataSource[index].owner_name;
                console.log("this.dataSource", this.dataSource);
              });
            } else {
              this.$message.error(response.message);
            }
            // this.loading.close();
          },
          (err) => {
            console.log(err, "err");
            this.loading.close();
          }
        );
      }
    },
    changeTab(tab, event) {
      this.useInfo()
      console.log("tab.name", tab.name);
      if (tab.name == "0") {
        //tab-My NFT
        this.getMyNFT();
      } else if (tab.name == "1") {
        //tab-history
        // this.dataSource = [];
        this.getMyHistory();
      } else if (tab.name == "2") {
        // this.dataSource = [];
        //Tab-Follow
        this.getMyCollected();
      }
    },
    getMyHistory() {
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
      } else {
        // this.showLoading();
        var para = {
          // address: this.address,
          page: 1,
          page_size: 1000,
        };
        this.dataSource = [];
        var time = Date.parse(new Date()) / 1000; //获取当前时间
        request.historyOfParticipation(para).then(
          (response) => {
            if (response.code == 200) {
              this.dataSource = response.data;
              this.dataSource.forEach((item, index) => {
                this.dataSource[index].isEnd = false;
                var endTime = parseInt(this.dataSource[index].start_game) + 600; //游戏结束时间
                if (time > endTime) {
                  this.dataSource[index].isEnd = true;
                }
                // this.dataSource[index].amount = ad.fromWei(
                //   this.dataSource[index].amount
                // );
                if(this.dataSource[index].next_sale_time){
                var date = new Date(item.next_sale_time)
                this.dataSource[index].next_sale_time = date.getTime();    
                console.log("3333",this.dataSource[index].next_sale_time)
               }else{
                this.dataSource[index].next_sale_time = 0;
               }
                var ac = parseInt(item.approve_count);
                var dc = parseInt(item.defuse_count);
                if (ac == 0 && dc == 0) {
                  this.dataSource[index].Rate = 0.5;
                } else {
                  this.dataSource[index].Rate = ac / (ac + dc);
                }
                if (
                  this.dataSource[index].result == 1 &&
                  this.dataSource[index].Choose == 1
                ) {
                  this.smile = false;
                  this.angry = true;
                } else if (
                  this.dataSource[index].result == 2 &&
                  this.dataSource[index].Choose == 2
                ) {
                  this.smile = false;
                  this.angry = true;
                } else if (
                  this.dataSource[index].result == 1 &&
                  this.dataSource[index].Choose == 2
                ) {
                  this.smile = false;
                  this.angry = true;
                } else if (
                  this.dataSource[index].result == 2 &&
                  this.dataSource[index].Choose == 1
                ) {
                  this.smile = false;
                  this.angry = true;
                } else {
                  this.smile = false;
                  this.angry = false;
                }
              });
            } else {
              this.$message.error(response.message);
            }
            this.loading.close();
          },
          (err) => {
            console.log(err, "err");
          }
        );
      }
    },
    getMyCollected() {
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
      } else {
        // this.showLoading();
        var para = {
          // address: this.address,
          page: 1,
          page_size: 1000,
        };
        this.dataSource = [];
        request.myCollect(para).then(
          (response) => {
            if (response.code == "200") {
              this.dataSource = response.data;
              this.dataSource.forEach((item, index) => {
                // let owner = this.dataSource[index].owner_name;
                // if (owner == this.address) {
                //   this.dataSource[index].isOwner = true;
                // } else {
                //   this.dataSource[index].isOwner = false;
                // }
                // this.dataSource[index].price = ad.fromWei(
                //   this.dataSource[index].price
                // );
                // this.dataSource[index].amount = ad.fromWei(
                //   this.dataSource[index].amount
                // );
                if(this.dataSource[index].next_sale_time){
                var date = new Date(item.next_sale_time)
                this.dataSource[index].next_sale_time = date.getTime();    
                console.log("3333",this.dataSource[index].next_sale_time)
               }else{
                this.dataSource[index].next_sale_time = 0;
               }
                var ac = parseInt(item.approve_count);
                var dc = parseInt(item.defuse_count);
                if (ac == 0 && dc == 0) {
                  this.dataSource[index].Rate = 0.5;
                } else {
                  this.dataSource[index].Rate = ac / (ac + dc);
                }
              });
            } else {
              this.$message.error(response.message);
            }
            this.loading.close();
          },
          (err) => {
            console.log(err, "err");
          }
        );
      }
    },
    isOnStartGame(item){
      if(this.isClick) {
        this.isClick = false;
        console.log('我被点击了');
        this.OnStartGame(item);
        }else{
        console.log('请勿过快点击');
        }
    },
    OnStartGame(tid) {
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login-m'});
        // this.$router.push({name:'login-m',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
          var that = this;
          var para = { 
            nft_id: tid,
            period:countDownTime
          };
          request.startGame(para).then((res) => {
            if (res.code == 200) {
              that.getMyNFT();
              setTimeout(()=>{ 
                that.isClick = true;
              },2000)
            }
          }).catch(function (error) {
            that.$message.error(error.message);
            setTimeout(()=>{ 
              that.isClick = true;
              },2000)
          });
        // ad.startGame(
        //   tid,
        //   (res) => {
        //     console.log(res);
        //     setTimeout(() => {
        //       location.reload();
        //     }, 1000);
        //   },
        //   (err) => {
        //     console.log(err);
        //     this.$refs.failed.openDialog();
        //   }
        // );
      }
    },
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: this.$t("lang.please"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mint-dialog {
  .left-img {
    text-align: center;
    img {
      max-height: 300px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: center;
      line-height: 40px;
      & > span {
        color: #000;
        font-weight: 600;
        margin-right: 5px;
      }
      img {
        width: 22px;
      }
    }
  }
  .mint-Confirm {
    text-align: center;
    margin-top: 10px;
    .el-button {
      background: #ffdda9;
      border: none;
      &:hover {
        border: none;
        color: #333;
        background: #fad398;
      }
    }
  }
}
.custom-tabs {
  /deep/ .el-tabs__nav-wrap::after {
  }
  /deep/ .el-tabs__active-bar {
    background: #12022f;
  }
  /deep/ .el-tabs__item.is-active {
    color: #12022f;
  }
  /deep/ .el-tabs__item {
    color: #959595;
    font-weight: 400;
    padding-right: 10px;
    &:hover {
      color: #12022f;
      opacity: 0.6;
    }
  }
}
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 5px;
  .custom-tabs {
    flex-grow: 1;
  }
  .more-dot {
    width: 50px;
    height: 50px;
    background: url("~@/assets/img/dots.svg") no-repeat center center/15px 3px;
    margin-left: 20px;
  }
}
// .empty-table {
//   line-height: 40px;
//   text-align: center;
//   color: #666;
//   font-size: 16px;
// }
.tabs-list {
  padding: 5px 5px 20px;
  min-height: calc(100vh - 330px);
  & > li {
    .list {
      & > div {
        display: flex;
        flex-wrap: wrap;
      }
      .list1,.list3 {
        width: 96%;
        margin: 0 auto 15px;
        margin-bottom: 15px;
        border: 1px solid #eee;
        padding: 10px;
        border-radius: 15px;

        .btn-info {
          display: flex;
          justify-content: space-around;
          padding: 7px 5px;
          align-items: center;
          & > div {
            width: 45%;
            text-align: center;
            border: 1px solid #ccc;
            border-radius: 15px;
            line-height: 30px;
            font-size: 12px;
            margin-right: 8px;
          }
          .Sell {
            background: #ffdda9;
            border-color: #ffdda9;
            color: #000;
          }
          img {
            width: 30px;
          }
        }
        .mitn_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 8px;
          span {
            background: #ffdda9;
            display: inline-block;
            width: 100%;
            text-align: center;
            border-radius: 14px;
            border: 1px solid #ccc;
            line-height: 26px;
            font-size: 12px;
          }
          .mint-button{
            background: #f2f2f2;
          }
          .del-button{
            background: #ff3916;
            color: #fff;
          }
        }
       
        .img-box {
          position: relative;
          overflow: hidden;
          height: 420px;
          .view {
            object-fit: cover;
            height: 100%;
            width: 99%;
            border-radius: 20px;
          }
          .bg-img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
          .mark {
            position: absolute;
            font-size: 12px;
            right: 17px;
            bottom: 9px;
            color: #841ae9;
          }
          .collect {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
            padding: 6px;
            border-radius: 100%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 22px;
            }
          }
          .card-info {
            position: absolute;
            width: 90%;
            border-radius: 6px;
            margin: 0 auto;
            background: #fff;
            right: 0;
            left: 0;
            bottom: 10%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 10px;
            box-shadow: 0px 0px 5px #ccc;
            p {
              display: flex;
              align-items: center;
              &:nth-child(1) {
                img {
                  width: 16px;
                }
                span {
                  color: #12022f;
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              img {
                width: 22px;
                margin-right: 4px;
              }
              span {
                color: #841ae9;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
          .ja-btn-info {
            flex-wrap: wrap;
            padding: 12px 5px 7px;
            & > div {
              width: 100%;
              margin-bottom: 5px;
            }
          }
          .info-text,
          .info-examin {
            position: absolute;
            width: 85%;
            border-radius: 20px;
            margin: 0 auto;
            background: #caffe6;
            color: #000;
            right: 0;
            left: 0;
            bottom: 32%;
            text-align: center;
            line-height: 30px;
          }
          .info-examin {
            bottom: 10%;
            span {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              background: #caffe6;
              border-radius: 20px;
              &.Failed {
                background: #ff3916;
                color: #fff;
              }
            }
          }
          .foot {
            position: absolute;
            width: 90%;
            border-radius: 6px;
            margin: 0 auto;
            background: #fff;
            right: 0;
            left: 0;
            bottom: 12%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            // border: 1px solid #ededed;
            padding: 0 5px;
            .progress-n {
              width: 100%;
            }
            div {
              .progress-num {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                span {
                  font-size: 14px;
                  font-weight: bold;
                  color: #6a2ac8;
                }
                .cor {
                  color: #fbad34;
                }
                .win {
                  background: url("~@/assets/img/win.png") no-repeat center
                    center/contain;
                  width: 26px;
                }
                .lose {
                  background: url("~@/assets/img/lose.png") no-repeat center
                    center/contain;
                  width: 30px;
                }
                .draw {
                  background: url("~@/assets/img/draw.png") no-repeat center
                    center/contain;
                  width: 30px;
                }
              }

              .progress {
                width: 100%;
                height: 4px;
                background: rgba(251, 173, 52, 1);
                text-align: center;
                color: #fff;

                .progressing {
                  position: relative;
                  float: left;
                  margin: 0 auto;
                  height: 4px;
                  background: #6a2ac8;
                }
              }
            }
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
        .info {
          .row {
            display: flex;
            justify-content: space-between;
          }
          h5 {
            color: #666;
            font-size: 13px;
            line-height: 25px;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          h4 {
            font-size: 15px;
          }
          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 22px;
            margin-top: 5px;
            width: 100%;
            p {
              display: flex;
              align-items: center;
              padding-right: 5px;
              img {
                width: 25px;
              }
            }
            & > div {
              & > span {
                padding-left: 0px;
                font-size: 12px;
              }
            }
            .icon {
              width: 32px;
            }
          }
          .rowItem {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .rows {
            & > div {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
      .list2 {
        width: 47%;
        margin: 0  6px;
        margin-bottom: 15px;
        border: 1px solid #eee;
        padding: 10px;
        border-radius: 15px;

        .btn-info {
          display: flex;
          justify-content: space-around;
          padding: 7px 5px;
          align-items: center;
          & > div {
            width: 45%;
            text-align: center;
            border: 1px solid #ccc;
            border-radius: 15px;
            line-height: 30px;
            font-size: 12px;
            margin-right: 8px;
          }
          .Sell {
            background: #ffdda9;
            border-color: #ffdda9;
            color: #000;
          }
          img {
            width: 30px;
          }
        }
        .mitn_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 8px;
          span {
            background: #ffdda9;
            display: inline-block;
            width: 100%;
            text-align: center;
            border-radius: 14px;
            border: 1px solid #ccc;
            line-height: 26px;
            font-size: 12px;
          }
          .mint-button{
            background: #f2f2f2;
          }
        }
       
        .img-box {
          position: relative;
          overflow: hidden;
          height: 180px;
          .view {
            object-fit: cover;
            height: 100%;
            width: 99%;
            border-radius: 20px;
          }
          .bg-img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
          .mark {
            position: absolute;
            font-size: 10px;
            right: 17px;
            bottom: 1px;
            color: #841ae9;
          }
          .collect {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
            padding: 6px;
            border-radius: 100%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 22px;
            }
          }
          .card-info {
            position: absolute;
            width: 90%;
            border-radius: 6px;
            margin: 0 auto;
            background: #fff;
            right: 0;
            left: 0;
            bottom: 10%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 10px;
            box-shadow: 0px 0px 5px #ccc;
            p {
              display: flex;
              align-items: center;
              &:nth-child(1) {
                img {
                  width: 16px;
                }
                span {
                  color: #12022f;
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              img {
                width: 22px;
                margin-right: 4px;
              }
              span {
                color: #841ae9;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
          .ja-btn-info {
            flex-wrap: wrap;
            padding: 12px 5px 7px;
            & > div {
              width: 100%;
              margin-bottom: 5px;
            }
          }
          .info-text,
          .info-examin {
            position: absolute;
            width: 85%;
            border-radius: 20px;
            margin: 0 auto;
            background: #caffe6;
            color: #000;
            right: 0;
            left: 0;
            bottom: 32%;
            text-align: center;
            line-height: 30px;
          }
          .info-examin {
            bottom: 10%;
            span {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              background: #caffe6;
              border-radius: 20px;
              &.Failed {
                background: #ff3916;
                color: #fff;
              }
            }
          }
          .foot {
            position: absolute;
            width: 80%;
            border-radius: 6px;
            margin: 0 auto;
            background: #fff;
            right: 0;
            left: 0;
            bottom: 12%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            // border: 1px solid #ededed;
            padding: 0 5px;
            .progress-n {
              width: 100%;
            }
            div {
              .progress-num {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                span {
                  font-size: 14px;
                  font-weight: bold;
                  color: #6a2ac8;
                }
                .cor {
                  color: #fbad34;
                }
                .win {
                  background: url("~@/assets/img/win.png") no-repeat center
                    center/contain;
                  width: 26px;
                }
                .lose {
                  background: url("~@/assets/img/lose.png") no-repeat center
                    center/contain;
                  width: 30px;
                }
                .draw {
                  background: url("~@/assets/img/draw.png") no-repeat center
                    center/contain;
                  width: 30px;
                }
              }

              .progress {
                width: 100%;
                height: 4px;
                background: rgba(251, 173, 52, 1);
                text-align: center;
                color: #fff;

                .progressing {
                  position: relative;
                  float: left;
                  margin: 0 auto;
                  height: 4px;
                  background: #6a2ac8;
                }
              }
            }
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
        .info {
          .row {
            display: flex;
            justify-content: space-between;
          }
          h5 {
            color: #666;
            font-size: 13px;
            line-height: 25px;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          h4 {
            font-size: 15px;
          }
          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 22px;
            margin-top: 5px;
            width: 100%;
            p {
              display: flex;
              align-items: center;
              padding-right: 5px;
              img {
                width: 25px;
              }
            }
            & > div {
              & > span {
                padding-left: 0px;
                font-size: 12px;
              }
            }
            .icon {
              width: 32px;
            }
          }
          .rowItem {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .rows {
            & > div {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
      .nodata {
        width: 95%;
        height: calc(100vh - 350px);
        margin: 0 auto;
      }
      .tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px 15px;

        div {
          width: 48%;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 12px;
          border-radius: 4px;
          border: 1px solid #ccc;

          span {
            margin-right: 5px;
            color: #666;
          }
        }
      }
    }
  }
}
/deep/.account-drawer {
  width: 70% !important;
  .account-mod {
    padding: 20px;
    .hd {
      font-size: 22px;
      padding: 10px 0;
    }
    .bd {
      .meta-item {
        padding: 10px 0 15px;
        position: relative;
        .item-title {
          color: #594d6d;
          font-size: 16px;
          padding: 10px 0 2px;
        }
        .item-value {
          .num {
            font-size: 28px;
          }
          .unit {
            font-size: 10px;
            margin-left: 4px;
          }
        }
        &:after {
          content: "";
          display: block;
          width: 16px;
          height: 2px;
          background: #d7d7d7;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .actions {
        padding: 40px 0 0;
      }
    }
  }
}
.btn {
  display: inline-block;
  border: #ccc 1px solid;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 20px;
  border-radius: 4px;
  color: #000;
  text-align: center;
  min-width: 130px;
  &.btn-rounded {
    border-radius: 10em;
  }
  &.btn-nft {
    background: #ffdda9;
    border-color: #ffdda9;
    color: #000;
  }
  &.gay {
    background: #ccc;
    border-color: #ccc;
    color: #fff;
  }
  &.btn-sm {
    font-size: 10px;
    line-height: 14px;
    padding: 2px 4px;
    min-width: 50px;
  }
}
//弹窗样式
.tooltip {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: #33333388;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 75%;
    background: #fff;
    padding: 15px 20px;
    border-radius: 8px;
    h2 {
      display: flex;
      align-content: center;
      justify-content: center;
      line-height: 25px;
      font-size: 16px;
      margin-bottom: 5px;
      color: #666;
    }
    p {
      font-weight: 600;
      line-height: 25px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      border-bottom: #dcdcdc solid 1px;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 8px 0;
      div {
        width: 48%;
        line-height: 30px;
        font-size: 12px;
        border: 1px solid #dcdcdc;
        border-radius: 25px;
        text-align: center;
        color: #12022f;
        &:last-child {
          background: #ffdda9;
          border-color: #ffdda9;
        }
      }
    }
  }
}
.confirm {
  h2 {
    font-size: 28px;
    color: #12022f;
    text-align: center;
  }
  .button {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    span {
      display: inline-block;
      width: 35%;
      text-align: center;
      background: #ffdda9;
      color: #000;
      line-height: 40px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}
</style>
<style>
.el-loading-spinner i {
  color: #dadce6;
  font-size: 30px;
}
.el-loading-spinner .el-loading-text {
  color: #dadce6;
  margin-top: 5px;
}
</style>